<template>
  <div id="Collapse">
    <b-button class="menu-collapse-title" v-b-toggle="'collapse-1'">
      <b-row>
        <b-col style="text-align: left">
          <slot />
          <b>{{ title }}</b>
        </b-col>
        <b-col style="text-align: right">
          <b-icon-chevron-down class="menu-collapse-icon" v-if="changeIcon" />
          <b-icon-chevron-up class="menu-collapse-icon" v-else />
        </b-col>
      </b-row>
    </b-button>
    <div>
      <div v-bind:key="button.text" v-for="button in buttons">
        <Button :route="button.route" :text="button.text" />
      </div>
    </div>
    <!--        <hr>-->
  </div>
</template>

<script>
import Button from '@/components/index/Button';
export default {
  name: 'Collapse',
  components: {
    Button,
  },
  props: {
    title: String,
    buttons: Array,
  },
  data() {
    return {
      changeIcon: false,
    };
  },
};
</script>

<style scoped>
#Collapse {
}

hr {
  margin: 0.1em 0 0 0;
  background-color: #6d6d6c;
  height: 1px;
  width: 100%;
  transition: font-size 1s;
}
b {
  padding-left: 0.2em;
}

.menu-collapse-title {
  text-align: center;
  font-size: 14px;
  color: #6d6d6c;
  text-transform: uppercase;
  background: none;
  border: none;
  width: 100%;
}
.menu-collapse-title:hover {
  color: #c31d1f;
}
.menu-collapse-title:focus {
  border: none;
  box-shadow: none;
}
.menu-collapse-title:active {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  color: #c31d1f !important;
}
.menu-collapse-icon {
  height: 14px;
  stroke: currentColor;
  stroke-width: 0.1em;
}
</style>
