<template>
  <div id="Sidebar">
    <b-row class="header-image">
      <b-col>
        <span>
          <a href="">
            <img
              :style="'width: ' + widthImg"
              alt="Schaffen"
              src="../../assets/schaffen.png"
            />
          </a>
        </span>
      </b-col>
    </b-row>
    <b-row class="menu-row" cols="1">
      <b-col class="menu-col">
        <Collapse :buttons="cadastroButtons" title="cadastro">
          <b-icon-tools />
        </Collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Collapse from '@/components/index/Collapse';
export default {
  name: 'Sidebar',
  components: {
    Collapse,
  },
  props: {
    widthImg: String,
  },
  data: function() {
    return {
      fixedMenu: 'fixed-menu',
      fixedText: 'Fixar Menu',
      rotateSvg: '',
      rotateClick: false,
      login: '',
      cadastroButtons: [
        {text: 'Campos', route: 'campos'},
        {text: 'Formulários', route: 'formularios'},
        {text: 'Grupos de Formulários', route: 'agrupamento_formularios'},
        {text: 'Motivos Visita', route: 'motivo_visita'},
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
#Sidebar {
}

img {
  width: 10em;
  padding: 0.5em 0.2em 0.2em 0.2em;
  transition: width 1s;
}
.menu-row {
  /*margin-top: 50vh;*/
  /*transform: translate(0, -50%);*/
}
.menu-col {
  padding-top: 4em;
}
</style>
