import Vue from 'vue';
import VueRouter from 'vue-router';
import CamposMain from '../components/campos/Main.vue';
import FormulariosMain from '../components/formularios/Main.vue';
import AgrupamentoFormulariosMain from '../components/agrupamentoFormularios/Main.vue';
import MotivoVisitaMain from '../components/MotivoVisita/Main.vue';
import Index from '../screens/Core/Index.vue';
import Login from '../screens/Core/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Index,
    children: [
      {
        path: '/campos',
        name: 'Campos',
        component: CamposMain,
      },
      {
        path: '/formularios',
        name: 'Formularios',
        component: FormulariosMain,
      },
      {
        path: '/agrupamento_formularios',
        name: 'AgrupamentoFormularios',
        component: AgrupamentoFormulariosMain,
      },
      {
        path: '/motivo_visita',
        name: 'MotivoVisita',
        component: MotivoVisitaMain,
      },
    ],
  },
];

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && localStorage.getItem('token') === null)
    next({name: 'Login'});
  else if (to.name === 'Login' && localStorage.getItem('token') !== null)
    next({name: 'Home'});
  else next();
  next();
});

export default router;
