import swal from "sweetalert2";
<template>
  <div id="Modal">
    <b-modal
      :id="id"
      @hidden="resetModal"
      @ok="handleOk"
      @show="resetModal"
      @hide="$emit('reset-modal')"
      cancelTitle="Cancelar"
      cancelVariant="outline-secondary"
      centered
      header-bg-variant="dark"
      header-text-variant="light"
      okTitle="Salvar"
      okVariant="dark"
      ref="modal"
      size="xl"
      title="Formulários"
    >
      <form @submit.stop.prevent="handleSubmit" ref="form">
        <b-form-group
          :state="stateDescription"
          label="Descrição do motivo visita:"
        >
          <b-form-input
            :state="stateDescription"
            placeholder="Descrição..."
            required
            v-model="description"
          />
        </b-form-group>

        <SortSelection
          :options="camposOptions"
          :edit="camposEdit"
          title="Pesquise os formulários que compõem o motivo visita:"
          v-on:selected-change="selectedChange($event)"
        />
      </form>
      <div class="message-alert">
        {{ messageAlert }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import SortSelection from '@/components/MotivoVisita/SortSelection';
import {FormService, VisitService} from '@/services';
import swal from 'sweetalert2';

export default {
  name: 'Modal',
  components: {
    SortSelection,
  },
  props: {
    id: String,
    row: Object,
    action: String,
  },
  data() {
    return {
      description: '',
      status: 1,
      campos: [],
      camposOptions: [],
      camposEdit: [],
      messageAlert: '',
      statusOptions: [
        {value: 1, text: 'Ativo'},
        {value: 0, text: 'Inativo'},
      ],
    };
  },
  computed: {
    stateDescription() {
      return this.description.length > 0;
    },
    stateStatus() {
      return this.status === 0 || this.status === 1;
    },
  },
  methods: {
    selectedChange(event) {
      this.campos = [];
      event.forEach(element => {
        this.campos.push({
          id: element.codigo,
          type: element.type,
        });
      });
    },
    resetModal() {
      this.description = '';
      this.status = 1;
      this.campos = [];
      this.camposEdit = [];
      //this.getForms();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.description !== '') {
        this.handleSubmit();
      }
    },
    reload(){
      location.reload();
    },
    handleSubmit() {
      if (!this.stateDescription || !this.stateStatus) {
        this.messageAlert =
          'Verifique se todos os campos foram preenchidos corretamente!';
        return;
      }
      if (this.action === 'new') {
        VisitService.newVisit({
          mov_descricao: this.description,
          associate: this.campos,
        }).then(res => {
          this.$bvModal.hide(this.id);
          location.reload();
        });
      }
      if (this.action === 'edit') {
        VisitService.updateVisit(this.row.codigo, {
          mov_descricao: this.description,
          associate: this.campos,
          mov_status: this.status,
        }).then(res => {
          this.$bvModal.hide(this.id);
          location.reload();
        });
      }
    },
    getForms() {
      if (this.action === 'new') {
        this.status = 1;
      }
      FormService.getAllForms({
        form_status: 1,
        orderBy: 'form_nome',
        perPage: false,
      }).then(res => {
        res.data.forEach(field => {
          this.camposOptions.push({
            text: field.form_nome,
            value: {
              label: field.form_nome,
              codigo: field.form_codigo,
              type: 'form',
            },
          });
        });
      });
    },
    getGroupsForms() {
      if (this.action === 'new') {
        this.status = 1;
      }
      FormService.getAllGroupings({
        foag_status: 1,
        orderBy: 'foag_descricao',
        perPage: false,
      }).then(res => {
        res.data.forEach(field => {
          this.camposOptions.push({
            text: field.foag_descricao,
            value: {
              label: field.foag_descricao,
              codigo: field.foag_codigo,
              type: 'group',
            },
          });
        });
      });
    },
  },
  mounted() {
    this.getForms();
    this.getGroupsForms();
  },
  watch: {
    row() {
      if (this.row.description) {
        this.description = this.row.description;
      }
      if (this.row.status) {
        this.status = this.row.status;
      }
      if (this.row.collapse) {
        this.row.collapse.forEach(element => {
          this.camposEdit.push({
            label: element.form_nome,
            codigo: element.form_codigo,
            type: element.type
          });
        });
      }
    },
  },
};
</script>

<style scoped>
#Modal {
}
.message-alert {
  margin-top: 1em;
  color: #dc3545;
  font-size: 14px;
}

input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}
</style>
