<template>
  <div id="Table">
    <b-table
      :fields="columns"
      :filter="filter"
      :items="rows"
      v-on:filtered="filtered($event)"
      hover
      no-border-collapse
      ref="selectableTable"
      responsive="lg"
      striped
    >
      <template v-slot:cell(status)="row">
        {{ row.item.status ? 'Ativo' : 'Inativo' }}
      </template>
      <template v-slot:cell(action)="row">
        <div
          v-on:click="
            $emit('edit-register', {
              row: row,
            });
          "
          class="btn-table-inner btn-table-inner-edit"
        >
          <b-icon-pencil class="icon-table-inner" />
        </div>
        <div
          v-if="row.item.status"
          v-on:click="
            $emit('delete-register', {
              codigo: row.item.codigo,
            })
          "
          class="btn-table-inner btn-table-inner-delete"
        >
          <b-icon-trash class="icon-table-inner" />
        </div>
      </template>
      <template v-slot:cell(collapse)="row">
        <div
          v-if="
            row.item.collapse !== undefined &&
              row.item.collapse !== null &&
              row.item.collapse.length > 0
          "
          v-b-toggle="row.item.name"
          class="btn-table-inner btn-table-inner-options"
          @click="row.toggleDetails"
        >
          <b-icon-box-arrow-up v-if="changeIcon" class="icon-table-inner" />
          <b-icon-box-arrow-down v-else class="icon-table-inner" />
        </div>
      </template>
      <template v-slot:row-details="row">
        <div class="table-collapse">
          <b-table
            :fields="fieldsCampos"
            :items="row.item.collapse"
            no-border-collapse
            responsive="lg"
            striped
          >
            <template v-slot:cell(cam_tipo)="row">
              {{ row.item.cam_tipo | type }}
            </template>
          </b-table>
        </div>
      </template>
    </b-table>
    <div style="padding-bottom: 1em">
      {{ noResult }}
    </div>
  </div>
</template>

<script>
import TableFilter from '@/components/formularios/TableFilter';
import swal from 'sweetalert2';
import {FormService} from '../../services';

export default {
  name: 'Table',
  props: ['columns', 'rows'],
  components: {
    TableFilter,
  },
  data: function() {
    return {
      changeIcon: false,
      filter: '',
      items: [],
      fieldsCampos: [
        {key: 'cam_codigo', label: 'Código', sortable: true},
        {key: 'cam_label', label: 'Campos', sortable: true},
        {key: 'cam_tipo', label: 'Tipo', sortable: true},
      ],
      tableRow: {},
      noResult: '',
    };
  },
  filters: {
    type(id) {
      switch (id) {
        case 1:
          return 'Assinatura';
        case 2:
          return 'Caixa de seleção';
        case 3:
          return 'Imagens';
        case 4:
          return 'Quantidade';
        case 5:
          return 'Múltipla escolha';
        case 6:
          return 'Valor';
        case 7:
          return 'Escolha única';
        case 8:
          return 'Verdadeiro ou Falso';
        case 9:
          return 'Texto';
        default:
          return '---';
      }
    },
  },
  methods: {
    filtered(event) {
      this.noResult = event.length === 0 ? 'Nenhum registro encontrado.' : '';
    },
    filterInputChange(event) {
      this.filter = event;
    },
    print(event){
      console.log(event);
    },
  },
};
</script>

<style scoped>
#Table {
  width: 100%;
}
.icon-table-inner {
  fill: currentColor;
  width: 2em;
  height: 2em;
  margin: 0.25em;
}
.btn-table-inner {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
}
.btn-table-inner-options {
  color: #ffffff;
  border: solid 1px #2c3e50;
  border-radius: 0.25rem;
  background: #2c3e50;
}
.btn-table-inner-options:hover {
  color: #2c3e50;
  border-color: #2c3e50;
  background-color: #ffffff;
  cursor: pointer;
}
.table-collapse {
  background-color: #ffffff;
  border: solid 1px #dee2e6;
  border-radius: 0.25rem;
}
.btn-table-inner-edit {
  margin-right: 1em;
  color: #ffffff;
  border: solid 1px #ffc107;
  border-radius: 0.25rem;
  background: #ffc107;
}
.btn-table-inner-edit:hover {
  color: #ffc107;
  border-color: #ffc107;
  background-color: #ffffff;
  cursor: pointer;
}
.btn-table-inner-delete {
  color: #ffffff;
  border: solid 1px #dc3545;
  border-radius: 0.25rem;
  background: #dc3545;
}

.btn-table-inner-delete:hover {
  color: #dc3545;
  border-color: #dc3545;
  background-color: #ffffff;
  cursor: pointer;
}
.menu-collapse-title {
  text-align: center;
  font-size: 14px;
  color: #6d6d6c;
  text-transform: uppercase;
  background: none;
  border: none;
  width: 100%;
}
.menu-collapse-title:hover {
  color: #c31d1f;
}
.menu-collapse-title:focus {
  border: none;
  box-shadow: none;
}
.menu-collapse-title:active {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  color: #c31d1f !important;
}
.menu-collapse-icon {
  height: 14px;
  stroke: currentColor;
  stroke-width: 0.1em;
}
</style>
