<template>
  <div id="Login" :style="'cursor:' + cursor">
    <div class="parallax"></div>
    <div class="login-card">
      <div class="login-margin">
        <div class="login-box">
          <b-row>
            <b-col class="login-form" sm="*">
              <form @submit="loginSubmit()">
                <img alt="Schaffen IT" src="../../assets/schaffen.png" />
                <UserName
                  @keyup.native.enter="loginSubmit()"
                  v-on:user-name-change="userName = $event"
                />
                <UserPassword
                  @keyup.native.enter="loginSubmit()"
                  :reset="resetPassword"
                  v-on:user-password-change="userPassword = $event"
                />
                <div class="form-button">
                  <ButtonReset
                    class="button-reset-margin"
                    v-on:login-reset="loginReset()"
                  />
                  <ButtonSubmit v-on:login-submit="loginSubmit()" />
                </div>
              </form>
            </b-col>
            <b-col class="login-text">
              <h4>Schaffen Sistemas</h4>
              <p><b>Além de soluções e parcerias de negócios!</b></p>
              <p>
                A Schaffen é uma empresa jovem, formada em 2014, mas traz
                consigo uma equipe com grande experiência, com mais de 18 anos
                em desenvolvimento de soluções na área de tecnologia da
                informação. Nossa proposta de valor é disponibilizar capital
                humano, intelectual e tecnológico para sua empresa focar apenas
                em seu negócio principal.
              </p>
              <p>
                Nosso negócio é criar soluções simples, enxutas e
                personalizadas. Soluções que cuidem do seu capital mais
                importante que é a informação. Temos habilidade, conhecimento e
                experiência em segurança de dados.
              </p>
              <p>
                Soluções modulares para gestão de workflow de processos,
                projetos, documentos, financeiro, fiscal, CRM, vendas, ordem de
                serviços, etc, Soluções para gestão de construção civil,
                imobiliárias, financeiras, fábrica de software, consultoria e
                auditoria de processos.
              </p>
              <p>
                Soluções para o agronegócio, enfim, seja qual for seu negócio,
                podemos lhe ajudar.
              </p>
            </b-col>
          </b-row>
        </div>
        <div class="login-footer">
          <b-row>
            <b-col>
              <p><b-icon-house-fill /><b> Endereço</b></p>
              <p>Av. Carlos Cavalcanti, 527 - Sala 1</p>
              <p>Uvaranas - Ponta Grossa - Paraná</p>
            </b-col>
            <b-col fluid="lg">
              <p><b-icon-chat-fill /><b> Telefone</b></p>
              <p>42 3229-4144 / 42 99911-2211</p>
              <p>42 99905-3038</p>
            </b-col>
            <b-col fluid="lg">
              <p><b-icon-envelope-fill /><b> Email</b></p>
              <a href="mailto:contato@schaffen-it.com"
                ><p>contato@schaffen-it.com</p></a
              >
              <a href="https://schaffen-it.com/" target="_blank"
                ><p>www.schaffen-it.com</p></a
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserPassword from '../../components/login/UserPassword';
import UserName from '../../components/login/UserName';
import ButtonReset from '../../components/login/ButtonReset';
import ButtonSubmit from '../../components/login/ButtonSubmit';

import {AuthService, UserService} from '@/services';
import store from '@/store';

export default {
  name: 'Login',
  components: {
    ButtonSubmit,
    ButtonReset,
    UserName,
    UserPassword,
  },
  data() {
    return {
      userName: '',
      userPassword: '',
      cursor: 'default',
      resetPassword: 0,
    };
  },
  methods: {
    loginSubmit() {
      // this.cursor = 'wait';
      const username = this.userName;
      const password = this.userPassword;
      this.resetPassword++;
      this.userName = '';
      this.userPassword = '';
      AuthService.login({
        username,
        password,
      })
        .then(response => {
          if (response) {
            UserService.getAuthenticatedPerson().then(response => {
              if (response !== null) {
                store.commit('setUser', response.pes_nome_reduzido);
                store.commit('changeAuthentication', true);
                this.$router.push('/formularios');
              }
            });
          }
        })
        .catch(error => {
          // this.cursor = "default";
        });
    },
    loginReset() {
      this.userName = '';
      this.userPassword = '';
    },
  },
};
</script>

<style scoped>
#Login {
}
img {
  margin-bottom: 2em;
  width: 80%;
}
b {
  font-size: 14px;
}
a {
  color: #ffffff;
}
h4 {
  font-weight: bold;
}
a:hover {
  color: #ffffff;
}
.parallax {
  background-image: url('https://schaffen-it.com/img/Capa.png');
  filter: blur(8px);
  -webkit-filter: blur(8px);
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-card {
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  display: grid;
  place-items: center;
  /*background: #cececf;*/
}
.login-margin {
  width: 50vw;
  padding: 0.3em;
  position: relative;
  background: linear-gradient(-45deg, darkred, red);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.login-box {
  background: #eaeaea;
  padding: 2rem 2rem 4rem 2rem;
}
.login-footer {
  background: none;
  padding: 2.1em 0.5em 0.5em 1.5em;
  /*background: #eaeaea;*/
  /*padding: 2rem 2rem 4rem 2rem;*/
  margin-left: 6em;
  color: #ffffff;
  text-align: left;
  line-height: normal;
  font-size: 12px;
}
.login-text {
  text-align: left;
  font-size: 14px;
  margin: 0 0 0 5em;
}

.login-form {
  text-align: center;
}

.button-reset-margin {
  margin-right: 2em;
}
@media only screen and (max-width: 720px) {
  .login-margin {
    width: 100vw;
  }
  .login-text {
    margin: 1em;
  }
  .button-reset-margin {
    margin-bottom: 1em;
  }
  .login-text {
    margin: 3em 0 0 0;
  }
  .login-footer {
    margin: 0;
  }
}
</style>
