<template>
  <div>
    <Title title="Cadastro de Motivos Visita"/>
    <Button
      text="Adicionar um Novo Motivo"
      v-on:add-fields-button="addFieldsButton()"
    />
    <TableFilter
      v-show="!this.loading"
      v-on:filter-input-change="search(1, $event)"
    />
    <Table
      v-show="!this.loading && fields.length > 0"
      :rows="fields"
      :columns="columns"
      @edit-register="editFields($event)"
      @delete-register="deleteRegister($event)"
    />
    <div class="text-center d-flex justify-content-center">
      <b-spinner
        v-show="this.loading"
        variant="danger"
        key="danger"
      ></b-spinner>
    </div>
    <b-pagination
      v-show="!this.loading && fields.length > 0"
      align="center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="fieldTable"
      @input="search"
    ></b-pagination>
    <p v-show="!this.loading && fields.length === 0">
      Nenhum registro encontrado.
    </p>
    <Modal
      :row="visitReason"
      :action="action"
      id="visitModal"
      v-on:reset-modal="resetModal()"
    />
  </div>
</template>

<script>
import Table from '@/components/generics/Table';
import Title from '@/components/generics/Title';
import Button from '@/components/generics/Button';
import {VisitService} from '@/services';
import Modal from '@/components/MotivoVisita/Modal';
import TableFilter from '@/components/MotivoVisita/TableFilter';
import swal from "sweetalert2";

export default {
  name: "Main",
  data() {
    return {
      action: "new",
      visitReason: {},
      loading: true,
      currentPage: 1,
      perPage: 3,
      totalRows: 0,
      fields: [],
      columns: [
        {key: 'description', label: 'Descrição', sortable: true},
        {key: 'status', label: 'Status', sortable: true},
        {key: 'action', label: 'Ações', sortable: false},
      ],
    };
  },
  components: {
    TableFilter,
    Modal,
    Button,
    Title,
    Table,
  },
  methods: {
    addFieldsButton() {
      this.$bvModal.show('visitModal');
    },
    editFields(params) {
      this.action = "edit";
      this.visitReason = params.row.item;
      console.log(this.visitReason);
      this.$bvModal.show('visitModal');
    },
    resetModal() {
      this.action = "new";
      this.visitReason = {};
    },
    deleteRegister(item) {
      swal
        .fire({
          title: 'Atenção',
          text: 'Você tem certeza que deseja excluir este motivo visita ?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Excluir',
          confirmButtonColor: '#c82333',
        })
        .then(result => {
          if (result.value) {
            VisitService.deleteVisit(item.codigo).then(() => {
              swal
                .fire({
                  title: '',
                  text: 'Motivo visita excluído com sucesso !',
                  icon: 'success',
                })
                .then(willDelete => {
                  if (willDelete) {
                    location.reload();
                  }
                });
            });
          }
        });
    },
    search(page, filters = null) {
      let params = {
        mov_status: 1,
        page: page,
        with: ["forms"],
      };

      if (filters) {
        params = {...filters, ...params};
      }

      this.loading = true;
      VisitService.getAllVisits(params)
        .then(res => {
          this.fields = [];
          this.currentPage = res.meta.current_page;
          this.perPage = res.meta.per_page;
          this.totalRows = res.meta.total;
          res.data.forEach(visit => {
            this.fields.push({
              codigo: visit.mov_codigo,
              description: visit.mov_descricao,
              status: visit.mov_status,
              collapse: visit.forms,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.search(1);
  },
};
</script>

<style scoped>

</style>
