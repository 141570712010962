<template>
  <div id="Index">
    <div class="index-sidebar">
      <Sidebar :width-img="widthImg" />
    </div>
    <div>
      <div class="sidebar-mask"></div>
      <div class="index-header">
        <Header />
      </div>
      <div class="index-body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/index/Sidebar';
import Header from '@/components/index/Header';
export default {
  name: 'Index',
  components: {
    Sidebar,
    Header,
  },
  data: function() {
    return {
      widthImg: '10em',
      fixedMenu: false,
    };
  },
};
</script>


<style scoped>
#Index {
}
.index-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 16em;
  padding: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: -1;
}
.index-header {
  position: absolute;
  top: 0;
  left: 16em;
  background-color: #c31d1f;
  height: 3em;
  width: calc(100vw - 16em);
  padding: 0 1em 0 0;
  transition: left 1s, width 1s;
}
.index-body {
  position: absolute;
  top: 3em;
  left: 16em;
  width: calc(100vw - 16em);
  background-color: #ffffff;
  padding: 0 2em 2em 2em;
  border-left: solid 1px #c31d1f;
  transition: left 1s, width 1s;
  z-index: 2;
}
.sidebar-mask {
  position: absolute;
  top: 3em;
  left: 16em;
  height: calc(100vh - 3em);
  background-color: #ffffff;
  padding: 0 2em 2em 2em;
  border-left: solid 1px #c31d1f;
  transition: left 1s, width 1s;
  z-index: 1;
}
</style>

<style>
  .error-message {
    color: red;
    font-size: 12px;
  }
</style>
