<template>
  <div id="Main">
    <Title title="Cadastro de Formulários" />
    <Button
      text="Adicionar um Novo Formulário"
      v-on:add-fields-button="addFieldsButton()"
    />
    <TableFilter
      v-show="!this.loading"
      v-on:filter-input-change="search(1, $event)"
    />
    <Table
      v-show="!this.loading && fields.length > 0"
      :rows="fields"
      :columns="columns"
    />
    <div class="text-center d-flex justify-content-center">
      <b-spinner
        v-show="this.loading"
        variant="danger"
        key="danger"
      ></b-spinner>
    </div>
    <b-pagination
      v-show="!this.loading && fields.length > 0"
      align="center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="fieldTable"
      @input="search"
    ></b-pagination>
    <p v-show="!this.loading && fields.length === 0">Nenhum registro encontrado.</p>
    <Modal action="new" id="addModal" />
  </div>
</template>

<script>
import Table from '@/components/formularios/Table';
import Title from '@/components/formularios/Title';
import Button from '@/components/formularios/Button';
import {FormService} from '@/services';
import Modal from '@/components/formularios/Modal';
import TableFilter from '@/components/formularios/TableFilter';
// import {ListRequest} from '@/services/api/config/types';
export default {
  name: 'Main',
  data() {
    return {
      loading: true,
      currentPage: 1,
      perPage: 3,
      totalRows: 0,
      fields: [],
      columns: [
        {key: 'name', label: 'Nome', sortable: true},
        {key: 'description', label: 'Descrição', sortable: true},
        {key: 'status', label: 'Status', sortable: true},
        {key: 'collapse', label: 'Campos', sortable: false},
        {key: 'action', label: 'Ações', sortable: false},
      ],
    };
  },
  components: {
    TableFilter,
    Modal,
    Button,
    Title,
    Table,
  },
  methods: {
    addFieldsButton() {
      this.$bvModal.show('addModal');
    },
    search(page, filters = null) {
      let params = {
        form_status: 1,
        page: page,
        with: ['fields'],
      };

      if (filters) {
        params = {...filters, ...params};
      }

      this.loading = true;
      FormService.getAllForms(params)
        .then(res => {
          this.fields = [];
          this.currentPage = res.meta.current_page;
          this.perPage = res.meta.per_page;
          this.totalRows = res.meta.total;
          res.data.forEach(form => {
            this.fields.push({
              codigo: form.form_codigo,
              name: form.form_nome,
              description: form.form_descricao,
              status: form.form_status,
              collapse: form.fields,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.search(1);
  },
};
</script>

<style scoped>
#Main {
}
</style>
